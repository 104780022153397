@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

@layer components {
  .c-h2 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 150%;
  }
  .c-h3 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 150%;
  }
  .c-h4 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 1rem;
    font-weight: 700;
    line-height: 150%;
  }
  .c-h5 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 150%;
  }
  .c-p {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 180%;
  }
  .c-p0 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 180%;
  }
  .c-p1 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 180%;
  }
  .c-p2 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 1rem;
    font-weight: 400;
    line-height: 180%;
  }
  .c-label1 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 180%;
  }
  .c-label2 {
    font-family: 'Pretendard Variable', Pretendard;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 150%;
  }
}

@layer base {
  :root {
    background-color: default;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  letter-spacing: -0.2px;
}

button,
a {
  cursor: pointer;
}

html,
body,
#__next {
  width: 100%;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

a {
  @apply text-blue;
}

a:hover {
  @apply border-b-2 border-blue;
}

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

.newgrid {
  display: grid;
  block-size: 250px;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: masonry;
  align-tracks: end, start, end, space-between;
}

body {
  overflow-x: hidden;
  background-color: #222529;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
}

.md-input-main {
  @apply w-full text-xl;
  width: 100%;
  font-size: 18px;
  color: white;
}
.md-input-box {
  @apply relative;
  position: relative;
}
.md-input {
  @apply w-full;
  width: 100%;
  outline: none;
  height: 56px;
  background-color: transparent;
}
.md-label {
  @apply pointer-events-none absolute block;
  display: block;
  position: absolute;
  pointer-events: none;
  transform-origin: top left;
  transform: translate(0, -40px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.md-input-underline {
  border-bottom: 1px solid #4b5057;
}
.md-input-underline:after {
  @apply pointer-events-none absolute left-0 right-0;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
  bottom: -0.05rem;
  content: '';
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: scaleX(0);
  border-bottom: 2px solid #f24c7e;
}
.md-input:focus ~ .md-input-underline:after {
  transform: scaleX(1);
}
